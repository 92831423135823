<template>
    <div id="preloader">
        <div class="loader">
            <div class="inner one"></div>
            <div class="inner two"></div>
            <div class="inner three"></div>
        </div>
        <div class="w3-container w3-center w3-display-middle">
            <p class="w3-xlarge pulse">Loading</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "loadingWeb"
}
</script>