import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/main.css'

const _URL_BASE = window.GLOBAL.URL_BASE

Vue.config.productionTip = false

Vue.use(VueSweetalert2);
Vue.mixin({
  data:function(){
    return {
      get URL_BASE(){ return _URL_BASE },
    }
  }
})

const app = new Vue({
  data:{loading: false},
  router,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to,from,next)=>{
  document.querySelector('body,html').scrollTop=0
  app.loading = true
  next()
})
router.afterEach(()=>{
  app.loading = false
  // setTimeout(() => app.loading = false, 1500) // timeout for demo purposes
})