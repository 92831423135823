<template>
  <div class="home">
    <!--START SLIDESHOW-->
    <section class="w3-container initSection w3-light-gray p-0">
      <div id="sliderHome" style="height:100%; width:100%;">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" style="position:relative;">
          <div class="sliderHome-Item sldr1">
            <div class="w3-center w3-container" style="padding-top:10%;">
              <!--CONTENT-->
            </div>
          </div>
          <div class="sliderHome-Item sldr2">
            <div class="w3-center w3-container" style="padding-top:10%;">
              <div class="w3-center">
                <figure class="m-0 text-center">
                  <img class="w3-image" src="@/assets/img/logodso.png" style="margin:0 auto;" />
                </figure>
                <div class="" style="background:#1d1d1f6b; height:100%; width:100%; position:absolute; left:0;">
                  <h1 class="w3-text-white">DarkStory 2D Online</h1>
                  <h3 class="w3-text-white">The entertaining ORPG addictive and wonderful</h3>
                  <a href="https://darkstory2d.com/" class="w3-button w3-orange" tabindex="-1" style="border-radius: 0.5em;background-image: linear-gradient(to bottom right, #9e4b0e, #eea200);border: 2px solid #c6722b;">
                    <h1 class="w3-text-white">PLAY NOW!!</h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="sliderHome-Item sldr3">
            <div class="w3-center w3-container">
              
              <div class="row">
                <div class="col-6 h-100-c">
                  <figure class="m-0 home_figurecustom01">
                    <img class="w3-image home_imgcustom02" src="@/assets/img/bslider1_1.png" />
                    <img class="w3-image home_imgcustom01" src="@/assets/img/bslider1_2.png" />
                  </figure>
                </div>
                <div class="col-6 my-auto">
                  <p class="w3-text-white w3-xxxlarge" style="text-shadow: 1px 5px 10px black;">"We create worlds for you"</p>
                  <figure class="home_figurecustom03">
                    <img class="w3-image" src="@/assets/img/logo_lobo.png" />
                  </figure>
                  <p class="w3-text-white w3-xlarge" style="text-shadow: 1px 5px 10px black;">BraveWolf is a Latin American study focused on the development of software and video games</p>
                  <router-link to="/games" id="seeOurProyect" class="w3-button w3-blue w3-hover-indigo">See our Projects</router-link>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>
    <!--END SLIDESHOW-->
    <!--START NEWS SYSTEM-->
    <MainNew></MainNew>
    <!--END NEWS SYSTEM-->
    <!--START RECENT NEWS SYSTEM-->
    <LastNews></LastNews>
    <!--END RECENT NEWS SYSTEM-->
    <!--START ABOUT bw-color3-->
    <About></About>
    <!--END ABOUT-->
    <!--START GAMES-->
    <Games></Games>
    <!--END GAMES-->
    <!--START SOCIAL-->
    <section class="w3-light-gray py-5">
      <div class="container py-2">
      <h1 class="w3-center " >Follow us on social networks</h1>
      <div class="w3-center">
        <a class="w3-button w3-margin-right w3-hover-blue-gray w3-padding-small bw-social-btn" href="https://www.facebook.com/bravewolfdev"><i class="fa fa-facebook w3-xxlarge" aria-hidden="true"></i></a>
        <a class="w3-button w3-margin-right w3-hover-blue-gray w3-padding-small bw-social-btn" href="https://twitter.com/BraveWolfDev"><i class="fa fa-twitter w3-xxlarge" aria-hidden="true"></i></a>
        <a class="w3-button w3-margin-right w3-hover-blue-gray w3-padding-small bw-social-btn" href="https://www.youtube.com/channel/UCeT7emdH3Bx-w3-wrEjys_Q"><i class="fa fa-youtube w3-xxlarge" aria-hidden="true"></i></a>  
      </div>
      </div>
    </section>
    <!--END SOCIAL-->
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import MainNew from '@/components/news/MainNew.vue'
import LastNews from '@/components/news/LastNews.vue'
import About from '@/components/home/About.vue'
import Games from '@/components/home/Games.vue'

export default {
  name: 'Home',
  created(){
    // do it
  },
  data(){
    return{}
  },
  components: {
    VueSlickCarousel,MainNew,LastNews,About,Games
  }
}
</script>

<style>
  .sliderHome-Item.sldr1 { background: url('../assets/img/bgmain1.jpg'); }
  .sliderHome-Item.sldr2 { overflow:hidden; background: url('../assets/img/toXSHRr2.jpg'); }
  .sliderHome-Item.sldr3 { overflow:hidden; background: url('../assets/img/bgmain2.jpg'); }
  .home_figurecustom01{ width:100%; height:100%; position:absolute; bottom:0; }
  .home_figurecustom02 { position: absolute; bottom: 11%; }
  .home_figurecustom03 { margin:0 auto; max-width: 100px }
  .home_imgcustom01{ position: absolute;bottom: 0;left: 19%; }
</style>