import Home from '@/views/Home.vue'
// import About from '@/views/About.vue'
// import Contact from '@/views/Contact.vue'
// import Games from '@/views/Games.vue'
// import Article from '@/views/Article.vue'
const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // component: About
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
      path: '/games',
      name: 'Games',
      // component: Games
      component: () => import(/* webpackChunkName: "games" */ '../views/Games.vue')

    }
    ,
    {
      path: '/contact',
      name: 'Contact',
      // component: Contact 
      component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
    },
    {
      path: '/article/view/id/:id',
      name: 'ViewArticle',
      // component:Article
      component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue')

    },
    {
      path: '/mainarticle/view/id/:id',
      name: 'ViewArticleMain',
      // component:Article
      component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue')

    }
]
export default routes