<template>
  <section class="w3-container w3-text-white w3-large w3-light-gray sectiondesc" style="overflow: hidden;" id="about">
    <div class="bw-barra-esquina-menu01 w3-dark-gray" style="top:0;"></div>
    <div class="bw-esquina-menu03" style="top:0; bottom: inherit; background:#616161;"></div>
    <div class="p-2"></div>
    <div class="row justify-content-center w3-text-dark-gray w3-center maxwidthview my-5" >
      <div class="col-md-6 col-lg-4">
        <img src="@/assets/img/bslider1_2.png" class="img-fluid" style="max-width:447px;" />
      </div>
      <div class="col-md-6 col-lg-4" >
        <p class="w3-xxlarge">
        Developers Team 
        <span class="w3-block"><i class="fa fa-users" aria-hidden="true"></i></span>
        </p>
        <p class="bw-fonts-arial" style="padding:0 5px;">
        Our group of professionals is characterized by teamwork and distinguished by their great skills in design, animation and technologies, united by the passion driven by each of the members of the team to create innovation, to offer the best Experience to our users and therefore apply it in any project offered by us.
        </p>
      </div>
      <div class="col-md-12">
        <div class="row justify-content-center py-5">
          <div class="col-2">
            <img src="@/assets/img/pgx.png" class="w3-image" />
            <div>
            <p><!--texto--></p>
            </div>
          </div>
          <div class="col-2">
            <img src="@/assets/img/logo-masgamers-festival.png" class="w3-image" />
            <div>
            <p><!--texto--></p>
            </div>
          </div>
          <div class="col-2">
            <img src="@/assets/img/lgf-logo-wred.png" class="w3-image" />
            <div>
            <p><!--texto--></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bw-barra-esquina-menu01 w3-dark-gray" style="left:0; right:inherit;"></div>
    <div class="bw-esquina-menu02" style="background:#616161;"></div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>