<template>
    <section class="w3-light-gray pt-4 pb-4">
      <div class="maxwidthview">
        <div class="w3-row">
          <div class="w3-col l6 m6 s6 p-0">
            <figure class="m-0"><img v-bind:src="this.img" class="w3-image" style="width:100%;" /></figure>
          </div>
          <div class="w3-col l6 m6 s6">
          <div class="newsbwsys_main_container p-3">
            <div class="newsbwsys_main_info font-weight-bold">
            <h5 class="m-0">{{this.tag}}</h5>
              <p>{{this.date}}</p>
            </div>
            <div class="newsbwsys_main_title">
              <h1>{{this.title}}</h1>
            </div>
            <div class="newsbwsys_main_description h4">
              <h4>{{this.description}}</h4>
            </div>
            <div class="newsbwsys_main_footer">
              <router-link v-bind:to="this.URL" class="btn w3-blue">Read more</router-link>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="p-3"></div>
    </section>
</template>
<script>
import imgDefault from '@/assets/img/slide04.png'

export default {
  name:'MainNew',
  data(){
    return {
      title: 'Empty Title NULL',
      tag:'General',
      date: '0000-00-00',
      description: 'Empty Description NULL',
      img: imgDefault,
      URL: '/',
    }
  },
  methods:{
    findMainNew(){
      const _URL = String.prototype.concat(window.GLOBAL.URL_BRAVEWOLF_OFFICE_API,"articlebwfixed/listado")
      let postdata = { estado:1,limit:1,orderBy:'DateArticle DESC'}
      let _d = new FormData();
      
      Object.keys(postdata) .forEach(e=>{ _d.append(e.toString(),postdata[e]); });
      
      fetch(_URL,{
        method:'POST',
        body:_d
      })
      .then(r=>r.json())
      .then(response => {
        if(response.code == 200 && Object.values(response.data).length > 0){
          const row = response.data[0]
          this.title = row.Title
          this.description = row.Description
          this.date = row.date_created
          this.URL = String.prototype.concat("/mainarticle/view/id/",row.id)
          this.img = row.Img
        }
      })
    }
  },
  created(){
    this.findMainNew()
  }
}
</script>
<style>
.newsbwsys_main_description{ overflow: hidden; }
</style>