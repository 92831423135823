<template>
  <section style="background-image: radial-gradient(circle farthest-corner at center, #1875e8  0%, #1C262B 100%);">
    <div class="container py-4">
      <h4 class="w3-text-white" style="border-bottom: 2px solid #b4cffb;">More recent news</h4>
      <div class="row justify-content-center">
        <New v-for="(Card,i) in newsCard" :index="i" :item="Card" :key="Card.id"></New>
      </div>
    </div>
  </section>
</template>

<script>
import New from '@/components/news/News.vue'

export default {
  name:'LastNews',
  components:{ New },
  data(){
    return{ 
      newsCard:[
        {id:1, title:'empty',description:'empty',author:'empty'},
        {id:2,title:'empty',description:'empty',author:'empty'},
        {id:3,title:'empty',description:'empty',author:'empty'},
      ]
    }
  },
  methods:{
    findNews(){
      const _URL = String.prototype.concat(window.GLOBAL.URL_BRAVEWOLF_OFFICE_API,"article/bw")
      let postdata = { limit:3, orderBy:'DateArticle DESC'  }
      let _d = new FormData();
      
      Object.keys(postdata) .forEach(e=>{ _d.append(e.toString(),postdata[e]); });
      
      fetch(_URL,{
        method:'POST',
        body:_d
      })
      .then(r=>r.json())
      .then(response => {
        if(response.code == 200 && Object.values(response.data).length > 0){
          let tmpArrNewsCard  = []

          response.data.forEach(el=>{
            tmpArrNewsCard.push({ id:el.id,title:el.Title,description:el.Description,author:el.Author, img:el.Img })
          })

          this.newsCard = tmpArrNewsCard
        }
      })
    }
  },
  created(){
    this.findNews()
  }
}
</script>

<style>

</style>