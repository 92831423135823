<template>
  <div id="app">
    <transition name="fade">
      <loadingWeb v-if="this.$root.loading"></loadingWeb>
    </transition>
    <header class="fixed-top bw-nav" v-bind:class="{'opacity':!this.showTopHeader}">
      <!--Social box-->
			<div class="w3-light-gray bw-social-box">
        <div class="w3-row">
            <div class="w3-col m6 bw-pl-2">
                <i class="fa fa-envelope w3-margin-right" aria-hidden="true"></i><b class="w3-small">contact@bravewolfdev.com </b>
            </div>
            <div class="w3-col m6" style="position: relative; z-index: 2;">
                <transition name="socialbox">
                  <div v-if="this.showTopHeader" class="bw-social-btn-container w3-right w3-margin-right w3-text-white">
                      <a class="w3-button w3-margin-right w3-hover-blue bw-social-btn" href="https://www.facebook.com/bravewolfdev"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                      <a class="w3-button w3-margin-right w3-hover-blue bw-social-btn" href="https://twitter.com/BraveWolfDev"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                      <a class="w3-button w3-margin-right w3-hover-blue bw-social-btn" href="https://www.youtube.com/channel/UCeT7emdH3Bx-w3-wrEjys_Q"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                  </div>
                </transition>
            </div>
        </div>
        <transition name="socialboxEsquina">
          <div v-if="this.showTopHeader" class="bw-barra-esquina-menu02"></div>
        </transition>
      </div>
      <!--Navbar-->
      <div id="BtnMovil" class="w3-black">
        <button class="w3-button w3-blue-gray w3-xlarge" onclick="w3_open()">☰</button>
      </div>
      <div id="navbar" class="w3-container w3-bar">
        <div class="bw-barra-esquina-menu01" v-if="this.showTopHeader"></div>
        <a href="/" class="scroll bw-logo"><img class="w3-image" src="./assets/img/logo_bw.png" /></a>
        <div class="w3-right w3-large mt-1">
          <router-link class="w3-bar-item text-white btn button" to="/">Home<div class="btn-color"></div></router-link>
          <router-link class="w3-bar-item text-white btn button" to="/about">About<div class="btn-color"></div></router-link>
          <router-link class="w3-bar-item text-white btn button" to="/games">Games<div class="btn-color"></div></router-link>
          <router-link class="w3-bar-item text-white btn button" to="/timeline">Timeline<div class="btn-color"></div></router-link>
          <router-link class="w3-bar-item text-white btn button" to="/contact">Contact<div class="btn-color"></div></router-link>
        </div>
      </div>
    </header>
    <!--START CONTENT-->
    <div style="margin-top:20px;"></div>
    <router-view></router-view>
    <!--END CONTENT-->
    <!--START FOOTER-->
		<div style="background-image: radial-gradient(circle farthest-corner at center, #1C262B 53%, #1875e8 100%);
        border-bottom: 2px solid #2b78f7;">
      <div class="bw-footer-back tiracustom"></div>
    </div>
    <footer id="footer-main" class="container pt-3">
      <div class="w3-row w3-text-blue-gray">
        <div class="w3-col s4 w3-container">
          <p class="w3-xlarge w3-text-white "><strong>About</strong></p>
          <p>BraveWolf is a studio developer of software and video games forged with talents from all over the world. Our mission is to create fantastic content, even the small details are important to us.</p>
          <i class="fa fa-envelope w3-margin-right" aria-hidden="true"></i><b>contact@bravewolfdev.com </b>
          <hr/>
          <div class=" w3-padding-small w3-margin-bottom">
            -
            <a class=" w3-margin-right w3-hover-text-white" href="https://www.facebook.com/bravewolfdev"><i class="fa fa-facebook w3-large" aria-hidden="true"></i></a>
            <a class=" w3-margin-right w3-hover-text-white" href="https://twitter.com/BraveWolfDev"><i class="fa fa-twitter w3-large" aria-hidden="true"></i></a>
            <a class=" w3-margin-right w3-hover-text-white" href="https://www.youtube.com/channel/UCeT7emdH3Bx-w3-wrEjys_Q"><i class="fa fa-youtube w3-large" aria-hidden="true"></i></a>
            -
          </div>
          
        </div>
        <div class="w3-col s8">
          <div class="row justify-content-center">
            <div class="col-sm-4 w3-container">
              <p class="w3-xlarge w3-text-white "><strong>Company</strong></p>
              <div>
                <!--<ul class="w3-ul bw-footer-list">-->
                <ul class="w3-text-blue-gray w3-large bw-list-footer">
                  <li><a href="https://m.me/bravewolfdev" target="_blank">Support</a></li>
                  <li><router-link to="/contact">Join Staff</router-link></li>
                  <!--<li><a href="#">Data Privacy Policy</a></li>-->
                  <li><a :href="`${this.urlBW}legal/terms.php`" target="_BLANK">Term & Conditions</a></li>
                </ul>
              </div>
            </div>
            <div class="col-sm-4 w3-container">
              <p class="w3-xlarge w3-text-white "><strong>Games</strong></p>
              <div>
                <!--<ul class="w3-ul bw-footer-list">-->
                <ul class="w3-text-blue-gray w3-large bw-list-footer">
                  <li><a :href="this.urlDSO" target="_blank">DarkStory Online</a></li>
                  <li><a href="javascript:void(0)" target="_blank">La batalla del nexo</a></li>
                  <li><a href="javascript:void(0)" target="_blank">Yhuriell Online</a></li>
                  <li><a href="javascript:void(0)" target="_blank">Invader House</a></li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </footer>
    <div class="footer-img" style="margin:0 auto;"><img src="@/assets/img/logo_lobo.png" /></div>
    <div class="w3-center w3-padding-large w3-text-white bw-color-1" >All rights Reserved BraveWolf 2010-2021</div>
    <!--END FOOTER-->
  </div>
</template>
<script>
import loadingWeb from './components/loadingWeb'

export default {
  data(){
    return {
      showTopHeader: false,
      urlBW: window.GLOBAL.URL_BRAVEWOLF,
      urlDSO: window.GLOBAL.URL_DARKSTORY
    }
  },
  components: { loadingWeb },
  methods:{
    onScroll(){
      if(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20){
        this.showTopHeader = true
      }else{
        this.showTopHeader = false
      }
    }
  },
  mounted(){
    window.addEventListener('scroll',this.onScroll)
  },
  destroyed(){
    window.removeEventListener('scroll',this.onScroll)
  }
}
</script>
<style>
</style>
