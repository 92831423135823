<template>
  <section class="w3-text-white" id="games" style="background-color:#00081c; position:relative; overflow:hidden;" >
    <div class="w3-row">
      <div class="w3-col l12 m12 py-3" style="background-image: radial-gradient(circle farthest-corner at center, #1875e8 0%, #1C262B 100%);
      color: #001739!important;">
        <h1 class="w3-center bw-fonts-archivo-black" style="font-style: italic;text-shadow: 0px 1px 8px #74dbca;">BraveWolf Games</h1>
      </div>
    </div>
      
    <div class="w3-container position-relative py-5">
      <div id="particle-games" style="position: absolute; top: 0; width:100%; text-align:center;"></div>
      <div class="row justify-content-center w3-margin-top w3-margin-bottom maxwidthview w3-center" style="position:relative; z-index: 1;">
        <div class="col-lg-3 col-md-4 col-sm-3">
        <figure class="d-inline-block m-0" style="box-shadow: 1px 10px 10px #091c31; overflow:hidden" >
          <img src="@/assets/img/g1.jpg" class="w3-round w3-image zoombackground" />
        </figure>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3">
        <figure class="d-inline-block m-0" style="box-shadow: 1px 10px 10px #091c31; overflow:hidden">
          <img src="@/assets/img/g3.jpg" class="w3-round w3-image zoombackground" />
        </figure>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3">
        <figure class="d-inline-block m-0" style="box-shadow: 1px 10px 10px #091c31; overflow:hidden">
          <img src="@/assets/img/g2.jpg" class="w3-round w3-image zoombackground" />
        </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'particles.js'
import configParticles from '@/assets/js/configParticles.js'

export default {
  methods:{
    initParticles(){
      window.particlesJS('particle-games',configParticles)
    }
  },
  mounted(){
    this.initParticles()
  }
}
</script>

<style>

</style>