<template>
  <div class="col-lg-4 col-md-4 col-sm-3">
    <div class="w3-white" style="border-radius:0.4em;">
      <router-link :to="String.prototype.concat('/article/view/id/',item.id)" style="text-decoration: none;">
      <figure class="newsbwsys_thrumb_image m-0">
        <img v-if="item.img == null" src="@/assets/img/recentnew.png" class="w3-image" />
        <img v-if="item.img != null" :src="item.img" class="w3-image zoombackground" />
      </figure>
      <div class="newsbwsys_thrumb_category py-2 pl-2">
        <p class="text-uppercase m-0 font-weight-bold">{{item.author}}</p>
      </div>
      <div class="newsbwsys_thrumb_title pb-2 pl-2">
        <h1 class="w3-large font-weight-bold">{{ item.title }}</h1>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props:['item'],
}
</script>

<style>
.newsbwsys_thrumb_image{ overflow: hidden; max-height: 196.5px; }
</style>